import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SelectPage = () => {

    return (
        <>
        </>
    );
};

export default SelectPage;